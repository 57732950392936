<template>
  <v-row class="filtrosinv">
        <v-btn variant="text" style="font-size: large;">Ventas </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->

        
        <v-btn prepend-icon="mdi-plus" color="indigo-darken-4" class="btnfilinv" @click="newVenta"
            >Nueva venta</v-btn>

        <v-btn prepend-icon="mdi-plus" color="indigo-darken-4"  class="btnfilinv"   @click="getGastos();gastos.dialog = true"
            >Gastos</v-btn>

        <v-select   class="selectenta"
            v-model="periodo"
            :items="['Solo hoy','Ultima semana', 'Ultimo mes', 'Seleccionar dia',  'Todo' ]"
            density="compact"
            label="Periodo"
        ></v-select>

        <v-btn v-if="diaSelec != ''"  @click="diadlg = true">
            <v-text-field  :disabled="true" type="date"  v-model="diaSelec" density="compact" 
            ></v-text-field>
        </v-btn>
        


        <v-tooltip text="Actualizar" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="indigo-darken-4" @click="getVentas" ></v-btn>
            </template>
        </v-tooltip>
    </v-row>

    <v-row style="margin-left:3%; margin-top:20px; padding-right: 15px; width: 95%; justify-content: center;">
        <v-chip class="ma-2" color="indigo-darken-4" variant="outlined">
        Total de ventas
        <span style="margin-left: 100px;">${{ TotVenta }}</span>
        </v-chip>
        <v-chip class="ma-2" color="indigo-darken-4" variant="outlined" v-if="user_id == 'Admin'">
        Total de Ganancias
        <span style="margin-left: 100px;">${{ ganT }}</span>
        </v-chip>
    </v-row>
    
    <v-card style=" margin-top: 40px;">
        <template v-slot:text>
            <v-text-field height="20px" v-model="search" label="Search" single-line variant="outlined" hide-details
                density="compact" style="width: 45%; margin-bottom: 10px;"></v-text-field>
        </template>
    
        <v-data-table :loading="loadingTable"
        :headers="Headers"
        :items="data"
        :search="search"
        items-per-page="25"
        density="compact"
        class="tabledts"
        >
            <template v-slot:[`item.id`]="{ item }">
                <v-btn variant="text" density="compact"  @click="openVentar(item); ">{{ item.id }}</v-btn>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
                <v-btn icon="mdi-printer" color="yellow" density="compact" @click="imprimirTicket(item.id, 'print')" style="margin-right: 5px;"></v-btn>
                <v-btn icon="mdi-delete" color="red" density="compact" @click="dialog.warning= true; dialog.message= 'Estas por elimar la venta No. '+ item.id; venta.id=item.id"></v-btn>
            </template>

        </v-data-table>
    </v-card>

    <v-dialog v-model="venta.dialog" transition="dialog-bottom-transition" width="70%">
        <v-card>
            <v-toolbar color="indigo-darken-4" :title="'Nueva venta  $'+venta.ventam"></v-toolbar>
            <v-card-text>
            <v-form @submit.prevent>
                    <v-row >
                        <v-col cols="12" sm="12" md="3">
                            <v-autocomplete density="compact"
                            label="Articulos"
                            v-model="coditem"
                            :items="venta.inventario"
                            item-title="Codigo"
                            item-value="Codigo"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" >
                            <v-select density="compact"
                                v-model="venta.formPgo"
                                label="Forma de pago"
                                :items="['Efectivo', 'Tarjeta', 'Transferencia']"
                            ></v-select>
                        </v-col>
                    
                        <v-col cols="12" sm="12" md="4">
                            <div style="font-size: small;">{{ venta.description }} <br> {{ venta.details }} </div>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="2" v-if="coditem != ''" @click="additemVnt">
                            <v-btn icon="mdi-plus"></v-btn>
                        </v-col>

                    
                    </v-row>      
                    <v-data-table :loading="loadingTable"
                    :headers="venta.headitems"
                    :items="venta.items"
                    items-per-page="25"
                    density="compact"
                    class="tabledts"
                    >
                        <template v-slot:[`item.gananc`]="{ item }">
                            {{parseFloat(item.PRCVNT)- parseFloat(item.PRCCMP)}}
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-btn icon="mdi-delete" color="red" density="compact" @click="deleteitemVnt(item)"></v-btn>
                        </template>

                    </v-data-table>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="venta.dialog = false ">Cerrar</v-btn>
                <v-btn variant="outlined" color="indigo-darken-4"  @click="savVenta">Completar venta</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



    <v-dialog v-model="gastos.dialog" transition="dialog-bottom-transition" width="70%">
        <v-card>
            <v-toolbar color="indigo-darken-4" title="Gestion de gastos"></v-toolbar>
            <v-card-text>
            <v-form @submit.prevent>
                    <v-row >
                        <v-col cols="12" sm="12" md="6">
                            <v-text-field label="Concepto" v-model="gastos.Concepto" density="compact" :rules="rules"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" >
                            <v-text-field type="number" v-model="gastos.Monto" density="compact" label="Cantidad" :rules="rules"></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" sm="12" md="3"  @click="addgasto">
                            <v-btn type="submit" variant="outlined" color="indigo-darken-4" >Agregar gasto</v-btn>
                        </v-col>

                    
                    </v-row>      
                    <v-data-table :loading="loadingTabledlg"
                    :headers="gastos.headitems"
                    :items="gastos.items"
                    items-per-page="25"
                    density="compact"
                    class="tabledts"
                    >
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-btn  icon="mdi-delete" color="red" density="compact" @click="gastos.id = item.id; dialog.type= 'gasto'; dialog.warning = true; dialog.message= 'espar por eliminar el gasto'+item.Concepto "></v-btn>
                        </template>

                    </v-data-table>
                    <v-card-actions class="justify-end">
                        <v-btn  variant="text" @click="gastos.dialog = false ">Cerrar</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
            
        </v-card>
    </v-dialog>




    <v-dialog v-model="dialogVenta.dialog" transition="dialog-bottom-transition" width="60%">
        <v-card>
            <!-- <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar> -->
            <v-card-text>
                <v-row >
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field label="Num de venta" v-model="dialogVenta.id" density="compact"  :disabled="true"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" >
                            <v-text-field density="compact" label="Fecha de venta" :disabled="true" v-model="dialogVenta.fchvnt" ></v-text-field>
                        </v-col>
                    
                        <v-col cols="12" sm="12" md="3"  >
                            <v-text-field density="compact" v-model="dialogVenta.total" label="Total de venta" :disabled="true" ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="3"  >
                            <v-select density="compact"
                                v-model="dialogVenta.formPgo"
                                label="Forma de pago"
                                :items="['Efectivo', 'Tarjeta', 'Transferencia']"
                            ></v-select>
                        </v-col>

                    
                    </v-row>
                    <v-data-table 
                    :headers="dialogVenta.headers"
                    :items="dialogVenta.datas"
                    items-per-page="25"
                    density="compact"
                    class="tabledts"
                    >
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-btn  icon="mdi-delete" color="red" density="compact" @click="deleteitem(item) "></v-btn>
                        </template>

                    </v-data-table>
                  
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="dialogVenta.dialog = false ">Cancelar</v-btn>
          
            </v-card-actions>
        </v-card>
    </v-dialog>








    <v-dialog v-model="dialog.warning" transition="dialog-bottom-transition" width="auto">
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
            <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="dialog.warning = false ">Cancelar</v-btn>
                <v-btn v-if="dialog.type != 'gasto'" variant="outlined" color="red-accent-4"  @click="deleteVenta">Eliminar venta</v-btn>
                <v-btn v-else variant="outlined" color="red-accent-4"  @click="deleteGasto">Eliminar Gasto</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



<v-snackbar v-model="dialog.confirmacion" :timeout="2000" color="deep-purple-accent-4" elevation="24">

<strong>{{ dialog.message }}</strong>
</v-snackbar>


<v-dialog v-model="dialog.error" transition="dialog-bottom-transition" width="auto">
<v-card>
    <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
    <v-card-text>
        <div class="text-h6 pa-12">{{dialog.message}}</div>
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn variant="text" @click="dialog.error = false ">Cerrar</v-btn>
    </v-card-actions>
</v-card>
</v-dialog>


<v-dialog v-model="diadlg" transition="dialog-bottom-transition" width="60%">
<v-card>
    <v-toolbar title="Selecciona dia"></v-toolbar>
    <v-card-text>
        <v-text-field type="date" label="Dia"  v-model="diaSelec" ></v-text-field>
        <!-- <div class="text-h6 pa-12">{{dialog.message}}</div> -->
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn variant="text" @click="diadlg = false ">Cerrar</v-btn>
        <v-btn variant="text" @click="filtrarDia ">Filtrar</v-btn>
    </v-card-actions>
</v-card>
</v-dialog>


<v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15"
    color="primary" indeterminate></v-progress-circular></v-dialog>

</template>

<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
    data(){
        return{
            diadlg: false,
            diaSelec: '',
            dia: false,
            fechad: '',
            periodo: 'Solo hoy',
            loadingTable: false,
            loadingTabledlg: false,
            search: '',
            Headers: [
                    { key: 'id', title: 'Num' ,align: 'center'},
                    { key: 'date', title: 'Fecha' ,align: 'center'},
                    { key: 'items', title: 'Articulos' ,align: 'center'},
                    { key: 'FRMPGO', title: 'Forma de pago' ,align: 'center'},
                    { key: 'Venta', title: 'Venta' ,align: 'center'},
                    // { key: 'Ganancia', title: 'Ganancia' ,align: 'center'},   
                    { key: 'delete', title: 'Acciones' ,align: 'center',sortable: false,},
                ],
            data:[],
            coditem: '',
            ganT: 0,
            TotVenta: 0,
            TotGanancia: 0,
            dialogVenta: {
                dialog:false,
                id:0,
                headers: [
                    { key: 'Codigo', title: 'Articulos' ,align: 'center'},
                    { key: 'Descripcion', title: 'Descripcion' ,align: 'center'},
                    { key: 'delete', title: 'Acciones' ,align: 'center',sortable: false,},
                ],
                datas: [],
                fchvnt: '',
                total: 0,
                formPgo: ''
            },
            venta: {
                id:0,
                dialog: false,
                Num: 0,
                Articulos: 0,
                ventam: 0,
                ganancia: 0,
                item: '',
                items:[],
                formPgo: 'Efectivo',
                inventario: [],
                description: '',
                details: '',
                
                headitems: [
                    { key: 'Codigo', title: 'Codigo' ,align: 'center'},
                    { key: 'Descripcion', title: 'Articulo' ,align: 'center'},
                    { key: 'PRCVNT', title: 'Venta' ,align: 'center'},
                    // { key: 'gananc', title: 'Ganancia' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ]
            },
            dialog: {
                    loading: false,
                    error: false,
                    warning: false,
                    confirmacion: false,
                    message: '',
                    type: ''
                },
            gastos:{
                dialog: false,
                headitems: [
                    { key: 'Concepto', title: 'Concepto' ,align: 'center'},
                    { key: 'MONTO', title: 'Cantidad' ,align: 'center'},
                    { key: 'DATE', title: 'Fecha' ,align: 'center'},
                    { key: 'TIME', title: 'Hora' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                items: [],
                id: 0,
                Concepto: "",
                Monto: 0.0,
            },
            rules: [
                value => {
                if (value) return true

                return 'Este campo no puede ir vacio'
                },
            ],
            user_id: ''
        }
    },
    created(){
        this.getinventarios();
        this.getVentas()

        this.user_id = window.localStorage.getItem('user_pazzia');

        if(this.user_id == 'Admin'){
            this.Headers = [
                    { key: 'id', title: 'Num' ,align: 'center'},
                    { key: 'date', title: 'Fecha' ,align: 'center'},
                    { key: 'items', title: 'Articulos' ,align: 'center'},
                    { key: 'FRMPGO', title: 'Forma de pago' ,align: 'center'},
                    { key: 'Venta', title: 'Venta' ,align: 'center'},
                    { key: 'Ganancia', title: 'Ganancia' ,align: 'center'},   
                    { key: 'delete', title: 'Acciones' ,align: 'center',sortable: false,},
                ]
        }


    },
    methods:{
        filtrarDia(){
            let fechsel = this.diaSelec.split('-');
            var fchFitro = fechsel[2] + '-' + fechsel[1]+ '-' + fechsel[0] ;
            // console.log(fchFitro)

            this.dialog.loading = true;
            this.loadingTable = true


            axios.get(url+"/ventas/filtracion/"+fchFitro)
            .then((result) => {  
            this.data = result.data.data
            this.TotVenta = result.data.Gobal.venT
            this.ganT = result.data.Gobal.GanT
            this.dialog.loading = false;
            this.loadingTable = false
            this.diadlg = false
            }).catch(e=>{console.log(e)})
        },
        openVentar(item){
            this.dialogVenta.dialog = true
            this.dialogVenta.id = item.id
            this.dialogVenta.fchvnt = item.date
            this.dialogVenta.total = item.Venta
            this.dialogVenta.formPgo = item.FRMPGO

            axios.get(url+"/ventas/"+item.id)
                .then((result) => {  
            this.dialogVenta.datas = result.data
            }).catch(e=>{console.log(e)})
            
        },
        deleteitem(item){
            this.dialog.loading = true
            axios.delete(url+"/ventas/devolution/"+item.id)
                .then((result) => {  
                if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Compra elimada correctamente"
                            this.dialog.loading = false
                            this.dialog.warning=false
                        this.dialogVenta.dialog = false
                            this.getVentas()
                            this.loadingTabledlg = false
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getVentas()
                            this.loadingTabledlg = false
                        }

            }).catch(e=>{console.log(e);
                this.dialog.loading = false;
                this.loadingTable = false})
        },
        newVenta(){
            this.venta.dialog = true;
            this.venta.items = []
            this.venta.Num =0
            this.venta.Articulos= ''
            this.venta.ventam= 0
            this.venta.ganancia= 0
            this.venta.formPgo= 'Efectivo'

        },
        getinventarios(){
            axios.get(url+"/inventario")
                .then((result) => {  
                this.venta.inventario = result.data

            }).catch(e=>{console.log(e)})
            },
        getVentas(){
            this.dialog.loading = true;
            this.loadingTable = true
            axios.get(url+"/ventas")
                .then((result) => {  
                this.data = result.data.data
                this.TotVenta = result.data.Gobal.venT
                this.TotGanancia =  result.data.Gobal.GanT
                this.ganT = result.data.Gobal.GanT

                this.dialog.loading = false;
                this.loadingTable = false

            }).catch(e=>{console.log(e)})
        },
        additemVnt(){
            this.venta.items.push(this.venta.item)
            this.coditem = ''
            this.venta.description = ''
            this.venta.details = ''
            this.venta.Articulos = this.venta.items.length
            this.venta.ventam = this.venta.ventam+ parseFloat(this.venta.item.PRCVNT)
            this.venta.ganancia = this.venta.ganancia+ parseFloat(this.venta.item.PRCVNT)- parseFloat(this.venta.item.PRCCMP)
            this.getinventarios()
        },
        imprimirTicket(id, tipo){
            let data = {
                "idVnt": id
            }
            axios.post(url + "/ventas/ticket",data)
                    .then((result) => {
                        console.log(result.data)
                    }).catch(e=>{console.log(e);
                        this.dialog.loading = false;
                this.loadingTable = false

                        if(tipo == 'print'){
                        
                this.dialog.error = true
                this.dialog.message = "No se pudo encontrar impresora "
                        }
                })
        },

        savVenta(){
            this.dialog.loading = true;
            let data ={
                'Num' : this.venta.Num,
                'Articulos' : this.venta.Articulos,
                'ventam' : this.venta.ventam,
                'ganancia' : this.venta.ganancia,
                'items' : this.venta.items,
                'formPgo' : this.venta.formPgo,
            }
            axios.post(url + "/ventas",data)
                    .then((result) => {
                        if (result.data.conf == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Venta Completada correctamente"
                            this.dialog.loading = false;
                            this.venta.dialog = false
                            this.imprimirTicket(result.data.id, 'nuevo')
                            this.getVentas()
                            this.getinventarios();

                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getVentas()
                            this.getinventarios();
                        }
                    }).catch(e=>{console.log(e)})

        },
        addgasto(){
            this.dialog.loading = true;
            this.loadingTabledlg = true
            let data ={
                'Concepto' : this.gastos.Concepto,
                'MONTO' : this.gastos.Monto,
            }
            if(this.gastos.Concepto == ''  || this.gastos.Monto <= 0){                
                this.dialog.loading = false;
                this.loadingTabledlg = false
                return 0;
            }

            axios.post(url + "/gastos",data)
                    .then((result) => {
                        if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Gasto Agregado correctamente"
                            this.dialog.loading = false;
                            // this.gastos.dialog = false
                            this.loadingTabledlg = false
                            this.getGastos()
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getGastos()
                        }
                    }).catch(e=>{console.log(e)})
        },
        getGastos(){
            this.loadingTabledlg = true
            axios.get(url+"/gastos")
                .then((result) => {  
                this.gastos.items = result.data
                this.loadingTabledlg = false

            }).catch(e=>{console.log(e)})
        },
        deleteGasto(){
            this.loadingTabledlg = true
            this.dialog.loading = true;
            axios.delete(url+"/gastos/"+this.gastos.id)
                .then((result) => {  
                if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Gasto elimado correctamente"
                            this.dialog.loading = false;
                            this.dialog.warning=false;
                            this.getGastos()
                            this.loadingTabledlg = false
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getGastos()
                            this.loadingTabledlg = false
                        }

            }).catch(e=>{console.log(e);
                this.dialog.loading = false;
                this.loadingTable = false})
        },
        deleteitemVnt(item){
            this.venta.items = this.venta.items.filter(itemds => itemds.Codigo != item.Codigo);
            this.coditem = ''
            this.venta.description = ''
            this.venta.details = ''
            this.venta.Articulos = this.venta.items.length
            this.venta.ventam = this.venta.ventam- parseFloat(item.PRCVNT)
            this.venta.ganancia = this.venta.ganancia- (parseFloat(item.PRCVNT)- parseFloat(item.PRCCMP))
        },
        deleteVenta(){
            this.dialog.loading = true;
            this.loadingTable = true

            axios.delete(url+"/ventas/"+this.venta.id)
                .then((result) => {  
                if (result.data == true) {
                    this.dialog.confirmacion = true
                    this.dialog.message = "Gasto elimado correctamente"
                    this.dialog.loading = false;
                    this.dialog.warning=false;
                    this.getVentas()
                    this.getinventarios();
                    this.loadingTabledlg = false
                } else {
                    this.dialog.loading = false
                    this.dialog.error = true
                    this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    this.getVentas()
                    this.getinventarios();
                    this.loadingTabledlg = false
                }
            
                this.dialog.loading = false;
                this.loadingTable = false

            }).catch(e=>{console.log(e);
                this.dialog.loading = false;
                this.loadingTable = false})
        }

    },
    watch: {
        coditem: function(value){
            if(value != ''){
                var itemd = this.venta.inventario.filter(function(e){
                return e.Codigo == value;
                });

                this.venta.item = itemd[0]
                this.venta.description = itemd[0].Descripcion 
                this.venta.details = 'Talla: '+itemd[0].TALLA+',  Marca:'+itemd[0].MARCA+',  Precio:'+itemd[0].PRCVNT
            }
        },  
        periodo: function(value){
            if(value == 'Seleccionar dia'){
                this.diadlg = true
            }else{
                this.diaSelec = ''
                this.dialog.loading = true;
                this.loadingTable = true


                axios.get(url+"/ventas/filtr/"+value)
                .then((result) => {  
                this.data = result.data.data
                this.TotVenta = result.data.Gobal.venT
                this.ganT = result.data.Gobal.GanT
                this.dialog.loading = false;
                this.loadingTable = false

            }).catch(e=>{console.log(e)})
            }
        
           
        }
    }
}
</script>

<style>

</style>