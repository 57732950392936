<template>
    <v-card v-if="session===false" class="loggin">
    <v-card class="logg">
    
        <v-text-field  label="Usuario" style="stop-color: antiquewhite;"
        variant="outlined"
        color="primary"
        prepend-icon="mdi-account"
        v-model="usuario"></v-text-field>

        <v-text-field  label="Contraseña"
        prepend-icon="mdi-lock"
        color="primary"
        variant="outlined"
        type="password"
        v-model="password"></v-text-field>
   
        <v-btn variant="outlined" color="#fff" style="background: #0074c7;" @click="login">
        Ingresar
        </v-btn>
    </v-card>
  </v-card>
  <v-card v-if="session==true">
    <navegation-bars @menuactiv="onMenu"></navegation-bars>
    <v-card class="content">
      <component :is="componentSelect"/>
    </v-card>
  </v-card>
  
</template>

<script>
import NavegationBars from './components/NavegationBars.vue'
import ApartadosPage from './screens/ApartadosPage.vue'
import DashboardPage from './screens/DashboardPage.vue'
import InventarioPage from './screens/InventarioPage.vue'
import VentasPage from './screens/ventasPage.vue'


import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
  components: { InventarioPage, NavegationBars, VentasPage, DashboardPage, ApartadosPage },

  name: 'App',
  data: () => ({
        contenido: "",
        componentSelect: null,
        session: false,
        usuario: '',
        password: ''
    }),
    created(){ 
      var token = localStorage.getItem('login')
      console.log(token)
      if(token == null){
        localStorage.clear()
        this.session = false
      }else if(token == 'false'){
        this.session = false
      }else{
        this.session = true
        var user = window.localStorage.getItem('user_pazzia');
        if(user == 'Admin'){
          this.componentSelect = 'dashboard-page';
        }
      }
    },
    methods:{
      onMenu(mn){      
          if(!mn.includes('-')){
            this.componentSelect = ""
            this.contenido = ""
          
          }else{
            this.componentSelect = mn
            this.contenido = mn
          }
        
          console.log(this.componentSelect)
      },
      login(){
        let datas = {
          "usuario": this.usuario,
          "password": this.password
        }
        console.log(datas)

        axios.post(url+"/user/login",datas)
            .then((result) => {  
            console.log(result.data)
            localStorage.setItem('login', result.data.log)
            localStorage.setItem('user_pazzia', result.data.user)

            window.location.reload()
        }).catch(e=>{console.log(e)})
      }
    
  }
}
</script>

<style>

#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 00px;
}


.content{
  width: 96%;
  height: 100vh;
  margin-left:4%;
  border-style: double;
  padding: 10px;
  overflow: auto;

}


  .loggin{
  width: 100%;
  height: 100vh;
  background-image: url(assets/baslog.jpg);
  background-size: 100% 100% ;

  display: flex;
  justify-content: center;
}

.logg{
  width: 50%;
  padding: 10%;
  padding-top: 50px;
  border-radius: 20px;
  height: 40vh;
  margin-top: 25vh;
  background: #c1e4ecd3;
}

.logo{width: 25%; margin-left: 37%; margin-bottom: 10%
}


@media (max-width:600px){
    .logg{
      width: 90%;
    }

    /* .loggin{
      background-image: url(assets/backmovil.jpeg);
    } */

    .logo{width: 35%; margin-left: 34%; margin-bottom: 15%
    }
}
</style>