    <template>
        <v-row  class="filtrosinv">
            <v-btn variant="text" style="font-size: large;">Apartados </v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->
    
            
            <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3" style="margin-left:5px; margin-bottom:20px "  @click="venta.dialog = true"
                >Nuevo apartado</v-btn>

            <v-tooltip text="Actualizar" location="bottom" >
                <template v-slot:activator="{ props }">
                    <v-btn class="btnfilinv"  v-bind="props" prepend-icon="mdi-reload" color="deep-orange-accent-3" @click="getVentas" 
                  ></v-btn>
                </template>
            </v-tooltip>
        </v-row>
    
        
        <v-card style=" margin-top: 40px;">
            <template v-slot:text>
                <v-text-field height="20px" v-model="search" label="Search" single-line variant="outlined" hide-details
                    density="compact" style="width: 45%; margin-bottom: 10px;"></v-text-field>
            </template>
        
            <v-data-table :loading="loadingTable"
            :headers="Headers"
            :items="data"
            :search="search"
            items-per-page="25"
            density="compact"
            class="tabledts"
            >
                <template v-slot:[`item.id`]="{ item }">
                    <v-btn variant="text" density="compact" @click="getApartado(item)" >{{ item.id }}</v-btn>
                </template>

                <template v-slot:[`item.Status`]="{ item }">
                <v-chip :color="item.Status == 'Pendiente'  ? 'yellow-darken-2': item.Status == 'Cancelado' ? 'red': 'green'" >{{ item.Status }}</v-chip>
            </template>


                <template v-slot:[`item.delete`]="{ item }">
                    <v-btn icon="mdi-delete" color="red" density="compact" @click="dialog.warning= true; dialog.message= 'Estas por elimar el apartado No. '+ item.id; venta.id=item.id"></v-btn>
                </template>
    
            </v-data-table>
        </v-card>
    
        <v-dialog v-model="venta.dialog" transition="dialog-bottom-transition" width="60%">
            <v-card>
                <v-toolbar color="indigo-darken-4" :title="'Nuevo apartado  $'+venta.ventam"></v-toolbar>
                <v-card-text>
                <v-form @submit.prevent>
                        <v-row >
                            <!-- <v-col cols="12" sm="12" md="3">
                                <v-text-field :disabled="true" v-model="venta.Num"  label="NUM" density="compact"></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field  v-model="venta.Nombre"  label="Nombre" density="compact" ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-text-field  v-model="venta.Contacto"  label="Contacto" density="compact"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-text-field  v-model="venta.anticipo"  label="Anticipo" density="compact"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="3">
                                <v-autocomplete density="compact"
                                label="Articulos"
                                v-model="coditem"
                                :items="venta.inventario"
                                item-title="Codigo"
                                item-value="Codigo"
                                ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" >
                                <v-select density="compact"
                                    v-model="venta.formPgo"
                                    label="Forma de pago"
                                    :items="['Efectivo', 'Tarjeta']"
                                ></v-select>
                            </v-col>


                            <v-col cols="12" sm="12" md="4">
                                <div style="font-size: small;">{{ venta.description }} <br> {{ venta.details }} </div>
                            </v-col>
                            
                            <v-col cols="12" sm="12" md="2" v-if="coditem != ''" @click="additemVnt">
                                <v-btn icon="mdi-plus"></v-btn>
                            </v-col>
    
                        
                        </v-row>      
                        <v-data-table :loading="loadingTable"
                        :headers="venta.headitems"
                        :items="venta.items"
                        items-per-page="25"
                        density="compact"
                        class="tabledts"
                        >
                            <template v-slot:[`item.gananc`]="{ item }">
                                {{parseFloat(item.PRCVNT)- parseFloat(item.PRCCMP)}}
                            </template>
                            <template v-slot:[`item.delete`]="{ item }">
                                <v-btn icon="mdi-delete" color="red" density="compact" @click="deleteitemVnt(item)"></v-btn>
                            </template>

    
                        </v-data-table>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn variant="text" @click="venta.dialog = false ">Cerrar</v-btn>
                    <v-btn variant="text" @click="savApartado">Completar venta</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
    
    
    
        <v-dialog v-model="apartado.completar" transition="dialog-bottom-transition" width="60%">
            <v-card>
                <v-toolbar color="orange-darken-3" :title="'Completar apartado No. '+ apartado.id"></v-toolbar>
                <v-card-text>
                    <v-row >
                        <v-col cols="12" sm="12" md="6">
                                <v-text-field  v-model="venta.Nombre"  label="Nombre" density="compact" ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field  v-model="venta.Contacto"  label="Contacto" density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                                <v-text-field :disabled="venta.anticipo > 0 ? false : true"  type="number" :max="venta.anticipo"
                                v-model="venta.abono"  :label="'Abono (Restante: '+venta.anticipo+')'"   prefix="$"
                                density="compact"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" >
                                <v-select density="compact"
                                    v-model="venta.formPgo"
                                    label="Forma de pago"
                                    :items="['Efectivo', 'Tarjeta']"
                                ></v-select>
                            </v-col>

                        <!-- <v-col cols="12" sm="12" md="3">
                                <v-text-field  disabled v-model="venta.anticipo"  label="Estatus" density="compact"></v-text-field>
                        </v-col> -->
                    </v-row>
                    <v-data-table :loading="loadingTable"
                        :headers="venta.headitems"
                        :items="venta.items"
                        items-per-page="25"
                        density="compact"
                        class="tabledts"
                        >
                        <template v-slot:[`item.gananc`]="{ item }">
                            {{parseFloat(item.PRCVNT)- parseFloat(item.PRCCMP)}}
                        </template>
                        <template v-slot:[`item.delete`]="{ item }">
                            <v-btn icon="mdi-delete" color="red" density="compact" @click="deleteitemVnt(item)"></v-btn>
                        </template>

    
                    </v-data-table>

                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn variant="text" @click="apartado.completar = false ">Cerrar</v-btn>
                    <!-- <v-btn variant="outlined" color="red" @click="completeApartado('Cancelado')">Cancelar apartado </v-btn> -->
                    <v-btn variant="flat" color="green" @click="completeApartado('Completar')">Registrar abono </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
    
        <v-dialog v-model="dialog.warning" transition="dialog-bottom-transition" width="auto">
            <v-card>
                <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
                <v-card-text>
                    <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn variant="text" @click="dialog.warning = false ">Cancelar</v-btn>
                    <v-btn  @click="deleteVenta">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    
    
    
    <v-snackbar v-model="dialog.confirmacion" :timeout="2000" color="deep-purple-accent-4" elevation="24">
    
    <strong>{{ dialog.message }}</strong>
    </v-snackbar>
    
    
    <v-dialog v-model="dialog.error" transition="dialog-bottom-transition" width="auto">
    <v-card>
        <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
        <v-card-text>
            <div class="text-h6 pa-12">{{dialog.message}}</div>
        </v-card-text>
        <v-card-actions class="justify-end">
            <v-btn variant="text" @click="dialog.error = false ">Cerrar</v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    
    
    <v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15"
        color="primary" indeterminate></v-progress-circular></v-dialog>
    
    </template>
    
    <script>
    import axios from "axios";
    const url = process.env.VUE_APP_SERVICE_URL; 
    export default {
        data(){
            return{
                periodo: 'Solo hoy',
                loadingTable: false,
                search: '',
                Headers: [
                        { key: 'id', title: 'Num' ,align: 'center'},
                        { key: 'Nombre', title: 'Nombre' ,align: 'center'},
                        { key: 'Contacto', title: 'Contacto' ,align: 'center'},
                        { key: 'date', title: 'Fecha' ,align: 'center'},
                        { key: 'Items', title: 'Articulos' ,align: 'center'},
                        { key: 'Restante', title: 'Restante' ,align: 'center'},
                        { key: 'Status', title: 'estado' ,align: 'center'},
                        { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                    ],
                data:[],
                coditem: '',
                ganT: 0,
                TotVenta: 0,
                apartado: {
                    completar: false,
                    status: '',
                    id: 0,
                    abono: 0
                },
                venta: {
                    id:0,
                    dialog: false,
                    abono: 0,
                    Num: 0,
                    Nombre: '',
                    Contacto: '',
                    anticipo: 0,
                    Articulos: 0,
                    ventam: 0,                    
                    item: '',
                    items:[],
                    formPgo: 'Efectivo',
                    inventario: [],
                    description: '',
                    details: '',
                    
                    headitems: [
                        { key: 'Codigo', title: 'Codigo' ,align: 'center'},
                        { key: 'Descripcion', title: 'Articulo' ,align: 'center'},
                        { key: 'PRCVNT', title: 'Venta' ,align: 'center'},
                        // { key: 'gananc', title: 'Ganancia' ,align: 'center'},
                        { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                    ]
                },
                dialog: {
                        loading: false,
                        error: false,
                        warning: false,
                        confirmacion: false,
                        message: '',
                        type: ''
                    },
            }
        },
        created(){
            this.getinventarios();
            this.getVentas()
        },
        methods:{
            getApartado(item){
                this.apartado.completar = true; 
                this.apartado.id = item.id; 
                axios.get(url+"/apartados/"+ item.id)
                    .then((result) => {  
                
                    this.venta.items = result.data.items
                    this.venta.Nombre =result.data.general.Nombre
                    this.venta.Contacto =result.data.general.Contacto
                    this.venta.anticipo = result.data.general.Restante
    
                }).catch(e=>{console.log(e)})

                
                this.venta.Nombre = '',
                this.venta.Contacto = ''
            },
            getinventarios(){
                axios.get(url+"/inventario")
                    .then((result) => {  
                    this.venta.inventario = result.data
    
                }).catch(e=>{console.log(e)})
                },
            getVentas(){
                this.dialog.loading = true;
                this.loadingTable = true
                axios.get(url+"/apartados")
                    .then((result) => {  
                    this.data = result.data
                    this.dialog.loading = false;
                    this.loadingTable = false
    
                }).catch(e=>{console.log(e)})
            },
            additemVnt(){
                this.venta.items.push(this.venta.item)
                this.coditem = ''
                this.venta.description = ''
                this.venta.details = ''
                this.venta.Articulos = this.venta.items.length
                this.venta.ventam = this.venta.ventam+ parseFloat(this.venta.item.PRCVNT)
                this.venta.ganancia = this.venta.ganancia+ parseFloat(this.venta.item.PRCVNT)- parseFloat(this.venta.item.PRCCMP)
            },
            savApartado(){
                let datos ={
                    "Nombre": this.venta.Nombre,
                    "Contacto": this.venta.Contacto,
                    "Anticipo": this.venta.anticipo,
                    "Status": 'Pendiente',
                    "ventam": this.venta.ventam ,
                    'items' : this.venta.items
                }

                axios.post(url + "/apartados",datos)
                    .then((result) => {
                        if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Apartado Completado correctamente"
                            this.dialog.loading = false;
                            this.venta.dialog = false
                            this.getVentas()
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getVentas()
                        }
                    })
            },
            completeApartado(action){
                let datos = {
                    'action': action,
                }
                axios.put(url + "/apartados/"+ this.apartado.id,datos)
                    .then((result) => {
                        if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "Apartado Completado correctamente"
                            this.dialog.loading = false;
                            this.venta.dialog = false
                            this.getVentas()
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                            this.getVentas()
                        }
                    })
            },
            deleteitemVnt(item){
                this.venta.items = this.venta.items.filter(itemds => itemds.Codigo != item.Codigo);
                this.coditem = ''
                this.venta.description = ''
                this.venta.details = ''
                this.venta.Articulos = this.venta.items.length
                this.venta.ventam = this.venta.ventam- parseFloat(item.PRCVNT)
                this.venta.ganancia = this.venta.ganancia- (parseFloat(item.PRCVNT)- parseFloat(item.PRCCMP))
            },
            deleteVenta(){
                this.dialog.loading = true;
                this.loadingTable = true
    
                axios.delete(url+"/apartados/"+this.venta.id)
                    .then((result) => {  

                    if (result.data == true) {
                        this.dialog.confirmacion = true
                        this.dialog.message = "Apartado Eliminada correctamente"
                        this.dialog.loading = false;
                        this.venta.dialog = false
                        this.dialog.warning = false;
                        this.loadingTable = false
                        this.getVentas()
                    } else {
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.warning = false;
                        this.loadingTable = false
                        this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        this.getVentas()
                    }
    
                }).catch(e=>{console.log(e);
                    this.dialog.loading = false;
                    this.loadingTable = false})
            }
    
        },
        watch: {
            coditem: function(value){
                if(value != ''){
                    var itemd = this.venta.inventario.filter(function(e){
                    return e.Codigo == value;
                    });
    
                    this.venta.item = itemd[0]
                    this.venta.description = itemd[0].Descripcion 
                    this.venta.details = 'Talla: '+itemd[0].TALLA+',  Marca:'+itemd[0].MARCA+',  Precio:'+itemd[0].PRCVNT
                }
            }
        }
    }
    </script>
    
    <style>
    
    </style>