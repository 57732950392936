<template>
    <v-layout class="menuMovil">
   <v-bottom-navigation grow color="indigo-darken-4">
        <!-- <v-btn value="recent">
            <v-icon>mdi-face-agent</v-icon>

            <span>Soporte</span>
        </v-btn> -->
        <v-btn value="nearby" @click="drawerMovil=!drawerMovil">
            <v-icon>mdi-account-box</v-icon>

            <span>Menu</span>
        </v-btn>
    </v-bottom-navigation>


    <v-navigation-drawer  v-model="drawerMovil"  permanent color="indigo-darken-4" style="width: 100%;" >
    
    <v-list-item style="height: 10px;"   append-icon="mdi-close" @click="drawerMovil=!drawerMovil"></v-list-item>
    <v-list-item style="height: 10px; " prepend-icon="mdi-account" :title="user_id" > </v-list-item>


    <v-tooltip   style="margin-top: 50%;" v-if="canBloques == 1 && canSesions == 1">
        <template v-slot:activator="{ props }">
            <v-list-item v-bind="props"  :prepend-avatar="require('../assets/'+menu.bloque.IMAGEN)"  :title="menu.bloque.BLOQUE" @click="openContent(menu.submen.component,menu.submen.lavel)" >
                <template v-slot:prepend>
                    <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                    </v-avatar>
                </template>
            </v-list-item>
        </template>
        <v-tooltip-text>
            <v-card  theme="dark" style="background: transparent;">
                <v-card-title style="font-size:medium;">{{ menu.bloque.BLOQUE }}</v-card-title>

            </v-card>
        </v-tooltip-text>
    </v-tooltip>






    <v-list density="compact" nav style="margin-top: 50%;" v-else>
    
        <v-tooltip v-if="this.user_id == 'Admin'">
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props"  title="Home"   @click="openContent('dashboard-page',0)">
                        <template v-slot:prepend>
                                <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-home-analytics</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">HOME</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de productos para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>


            

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" title="Ventas"   @click="openContent('ventas-page',0)">
                        <template v-slot:prepend>
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-sale</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">VENTAS</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de ventas para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" title="Apartados"   @click="openContent('apartados-page',0)">
                        <template v-slot:prepend>
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-store-plus</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Apartados</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de apartdos para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>



            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props"  title="Inventarios"   @click="openContent('inventario-page',0)">
                        <template v-slot:prepend >
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-warehouse</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">INVENTARIOS</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de inventario para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

    </v-list>


    <v-divider></v-divider>

    <v-list-item  style="height: 10px; " prepend-icon="mdi-logout" title="cerrar sesion" @click="logOut" > </v-list-item>


        </v-navigation-drawer>

</v-layout> 

<v-layout class="menudesk" >
        <v-navigation-drawer  v-model="drawer" :rail="rail" permanent color="indigo-darken-4" >
        <v-list-item style="height: 10px;" v-if="rail == true"
            prepend-icon="mdi-menu"
            @click="rail=!rail">
            </v-list-item>
        <v-list-item style="height: 10px;" v-else  append-icon="mdi-chevron-left" @click="rail=!rail"></v-list-item>
        <v-list-item style="height: 10px; " prepend-icon="mdi-account" :title="user_id" > </v-list-item>


        <v-list density="compact" nav style="margin-top: 90%;">

            <v-tooltip v-if="this.user_id == 'Admin'">
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props"  title="Home"   @click="openContent('dashboard-page',0)">
                        <template v-slot:prepend>
                                <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-home-analytics</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">HOME</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de productos para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>


            

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" title="Ventas"   @click="openContent('ventas-page',0)">
                        <template v-slot:prepend>
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-sale</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">VENTAS</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de ventas para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" title="Apartados"   @click="openContent('apartados-page',0)">
                        <template v-slot:prepend>
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-store-plus</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Apartados</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de apartdos para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>



            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props"  title="Inventarios"   @click="openContent('inventario-page',0)">
                        <template v-slot:prepend >
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-warehouse</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">INVENTARIOS</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de inventario para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

        


<!-- 
            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" title="Configuraciones" value="Configuraciones" @click="openContent('users-sistem',0)">
                        <template v-slot:prepend>
                            <v-avatar  style="width: 30px; height: 30px; margin-left: -5px;">
                                <v-icon color="white">mdi-cog</v-icon>
                            </v-avatar>
                        </template></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">CONFIGURACIONES</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de clientes para <br> agregar, editar y eliminar
                        </v-card-text>
                    </v-card>
                </v-tooltip-text>
            </v-tooltip> -->
        </v-list>


        <v-divider></v-divider>
        <v-list-item v-if="rail == false" style="height: 10px; " prepend-icon="mdi-logout" title="cerrar sesion" @click="logOut" > </v-list-item>
        </v-navigation-drawer>
    </v-layout>



</template>

<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL
export default {
    emits: ['menuactiv', 'lavel'],
    data(){
        return{
            menufe:false,
            menuUser: false,
            drawerMovil: false,
            canBloques: 0,
            canSesions: 0,
            user: 'usuario',
            section: 'hfhjfgh',
            user_id:0,
            drawer: true,
            rail: true,
            menu: {
                bloques: [],
                bloque: [],
                submen: []
            }
            }
    },
    created(){
        this.user_id = window.localStorage.getItem('user_pazzia');
        console.log(this.user_id)
        // this.getdataUser()
    },
    methods:{
        async getdataUser(){
            await axios.get(url + "/users/"+ this.user_id )
            .then((result) => {
                console.log(result.data);
                this.menu.bloques = result.data.bloques
                this.loading= false
                this.canBloques = result.data.bloques.length;
                this.user = result.data.user.name
                this.section = result.data.user.asignacion

                if(result.data.bloques.length == 1){
                    this.menu.bloque =   result.data.bloques[0].bloque
                    this.canSesions = result.data.bloques[0].subs.length;
                    if(result.data.bloques[0].subs.length == 1)
                    {
                        this.menu.submen = result.data.bloques[0].subs[0]
                        var paged = result.data.bloques[0].subs[0];
                        console.log(paged.component)
                        console.log(paged.lavel)
                        this.openContent(paged.component, paged.lavel)
                    }

                    console.log(this.menu.bloque.component)
                    if(this.menu.bloque.id == 2){
                        this.openContent(this.menu.bloque.component, 0)
                    }
                }

                // if


            }).catch((e) => {
                    console.log(e);
                    this.loading= false
                    this.dialog.error = true
                    this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                });
        },
        async logOut(){
            localStorage.clear()
            window.location.reload()
        },
        openContent(page,nivel){
            this.mosSubmen = false
            this.menutal = false
            this.menuActiv = page
            this.$emit('menuactiv', page, nivel)
            this.drawerMovil = false
        },
    }
}

</script>