<template>

<v-row  class="filtrosinv" style="margin-bottom: 20px">
        <v-btn variant="text" style="font-size: large;">Inventario</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->

        <v-tooltip text="Actualizar" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn class="btnfilinv" v-bind="props" prepend-icon="mdi-reload" color="indigo-darken-4" @click="get"></v-btn>
            </template>
        </v-tooltip>
        <v-btn prepend-icon="mdi-plus" color="indigo-darken-4"  class="btnfilinv" @click="inventario.newc=true; newinv();"
            >Nuevo inventario</v-btn>
        <v-tooltip text="Catalogos" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-book-open-page-variant" color="deep-orange-accent-3"
                    @click="catalogos.dialog= true"  style="margin-left:5px;" ></v-btn>
            </template>
        </v-tooltip>
    </v-row>
    
    <v-card>
        <template v-slot:text>
            <v-text-field height="20px" v-model="search" label="Search" single-line variant="outlined" hide-details
                density="compact" style="width: 45%; margin-bottom: 10px;"></v-text-field>
        </template>
    
        <v-data-table :loading="loadingTable"
        :headers="inventario.headers"
        :items="inventario.data"
        :search="search"
        items-per-page="25"
        density="compact"
        class="tabledts"
        >
            <template v-slot:[`item.Codigo`]="{ item }">
                <v-btn variant="text" density="compact" @click="updtinv(item)">{{ item.Codigo }}</v-btn>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
                <v-btn icon="mdi-delete" color="red" density="compact" @click="dialog.type='inventario'; dialog.warning=true; dialog.message='Estas por eliminar '+item.Codigo; inventario.id=item.id"></v-btn>
            </template>

        </v-data-table>
    </v-card>



    <v-dialog v-model="inventario.newc" transition="dialog-bottom-transition" width="70%">
        <v-card>
            <v-toolbar color="indigo-darken-4" title="+ Nuevo elemento"></v-toolbar>
            <v-card-text>
                <v-form @submit.prevent>
                    <v-row>
                        <v-col cols="12" sm="12" md="4">
                            <v-text-field v-if="inventario.Cod == ''" maxlength="3" :disabled="true" v-model="inventario.Cod"  label="Codigo" density="compact"></v-text-field>
                            <v-btn v-else prepend-icon="mdi-pencil" variant="text" style="width: 90%; font-size: 17px;" 
                            @click="showdtEdit"> {{inventario.Cod}} </v-btn>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field v-model="inventario.Descripcion " :rules="rules" density="compact"
                                label="Descripcion"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                            <v-select clearable v-model="inventario.tipo" label="Tipo" :items="tipos.data" density="compact" :rules="rules" 
                                item-title="DESCRIPCION" item-value="id">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                            <v-select clearable v-model="inventario.marca" label="Marca" :items="marcas.data " density="compact" :rules="rules" 
                                item-title="MARCA" item-value="id">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="4">
                            <v-select clearable v-model="inventario.talla" label="Talla" :items="tallas.data " density="compact" :rules="rules"
                                item-title="TALLA" item-value="id">
                            </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="inventario.cantidad " density="compact" :rules="rules"
                                label="Cantidad"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field  v-model="inventario.color" density="compact" 
                                label="Color"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field   type="number" v-model="inventario.prccom " :rules="rules" density="compact" 
                                label="Precio de compra"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="inventario.prcvnt " :rules="rules" density="compact"
                                label="Precio de venta"></v-text-field>
                        </v-col>
                    </v-row>
                    <div v-if="inventario.id > 0">
                        Imagenes 
                        <v-divider></v-divider>
                        <v-row  style="width: 100%; margin-top: 30px; margin-bottom: 35px;">
                            <div v-for="imgsd in inventario.images" :key="imgsd">
                                <v-img  :src="url_images+imgsd.image " style="width: 170px; height: 170px;" ></v-img>
                                <v-chip color="red" density="compact" class="text-center pa-4" @click="deleteimg(imgsd)" >x Eliminar</v-chip>
                            </div>
                            <v-btn v-if="inventario.images.length < 5" icon="mdi-plus" @click="inventario.img = true"></v-btn>
                        </v-row>
                        
                    </div>
                    <v-card-actions class="justify-end">
                        <v-btn variant="text" @click="inventario.newc = false; ">Cancelar</v-btn>
                        <v-btn type="submit" variant="tonal" color="indigo-darken-4" @click="saveInventario" >Guardar</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>


    </v-dialog>



    <v-dialog v-model="inventario.img" transition="dialog-bottom-transition" class="widthdialog">
        <v-card>
            <v-card-title>Cargar imagenes</v-card-title>
            <v-card-text>
                <v-file-input id="imagesinv"
                    :rules="rules"
                    accept="image/png, image/jpeg, image/bmp"
                    label="Imagenes"
                    multiple
                    chips
                    placeholder="Selecciona imagnes"
                    prepend-icon="mdi-camera"
                ></v-file-input>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="inventario.img = false; ">Cancelar</v-btn>
                <v-btn type="submit" @click="saveImages">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="inventario.saveinv" transition="dialog-bottom-transition"  class="widthdialog">
        <v-card>
            <v-toolbar color="green" title="Proceso exitoso"></v-toolbar>
            <v-card-text  class="text-center pa-4">
                {{ dialog.message }}
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn  color="green" variant="text" @click="inventario.saveinv = false; inventario.newc=false;">Cerrar</v-btn>
                <v-btn  color="green" variant="outlined"  @click="inventario.img = true">Agregar imagenes</v-btn>
                <v-btn  color="green" variant="tonal"  @click="newinv(); inventario.saveinv=false ">Agregar nuevo</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



    <v-dialog v-model="catalogos.dialog" transition="dialog-bottom-transition"  class="widthdialog"  scrollable>
        <v-card style="padding:15px;">
            <v-card-title>Catalogos</v-card-title>
            <v-tabs v-model="catalogos.type" color="deep-purple-accent-4" align-tabs="start">
                <v-tab value="TIPOS">TIPO</v-tab>
                <v-tab value="MARCAS">MARCA</v-tab>
                <v-tab value="TALLAS">TALLA</v-tab>
            </v-tabs>
            <v-window v-model="catalogos.type" style="max-height: 80%; overflow: auto">
                <v-window-item value="TIPOS" style="padding:15px; margin-right:10px;">
                    <v-row style="margin-bottom:10px">
                        <v-spacer></v-spacer><v-btn prepend-icon="mdi-plus" color="cyan-darken-4" @click="newcat">Nuevo
                        </v-btn>
                    </v-row>
                    <v-data-table :loading="loadingTabledlg"
                        :headers="tipos.headers"
                        :items="tipos.data"
                        :search="search"
                        items-per-page="25"
                        density="compact"
                        class="tabledts"
                        >
                        <template v-slot:[`item.delete`]="{ item }">
                                <v-btn icon="mdi-delete" color="red" density="compact"  @click="catalogos.id = item.id; dialog.type='catalogos'; dialog.warning= true; dialog.message= 'estas por eliminar un catalogo'"  ></v-btn>
                            </template>

                        </v-data-table>
                </v-window-item>
                <v-window-item value="MARCAS" style="padding:15px; margin-right:10px; ">
                    <v-row style="margin-bottom:10px">
                        <v-spacer></v-spacer><v-btn prepend-icon="mdi-plus" color="cyan-darken-4" @click="newcat">Nuevo
                        </v-btn>
                    </v-row>

                    <v-data-table :loading="loadingTabledlg"
                    :headers="marcas.headers"
                    :items="marcas.data"
                    :search="search"
                    items-per-page="25"
                    density="compact"
                    class="tabledts"
                    >

                    <template v-slot:[`item.LOGO`]="{ item }">
                        <v-img :src="url_images+item.LOGO" style="width: 30%; margin-left: 30%;" ></v-img>
                    </template>

                    <template v-slot:[`item.delete`]="{ item }">
                            <v-btn icon="mdi-delete" color="red" density="compact" @click="catalogos.id = item.id; dialog.type='catalogos'; dialog.warning= true; dialog.message= 'estas por eliminar un catalogo'"  ></v-btn>
                        </template>

                    </v-data-table>
                
                </v-window-item>
                <v-window-item value="TALLAS" style="padding:15px; margin-right:10px;">
                    <v-row style="margin-bottom:10px">
                        <v-spacer></v-spacer><v-btn prepend-icon="mdi-plus" color="cyan-darken-4" @click="newcat">Nuevo</v-btn>
                    </v-row>

                    <v-data-table :loading="loadingTabledlg"
                    :headers="tallas.headers"
                    :items="tallas.data"
                    :search="search"
                    items-per-page="25"
                    density="compact"
                    class="tabledts"
                    >
                    <template v-slot:[`item.delete`]="{ item }">
                            <v-btn icon="mdi-delete" color="red" density="compact" @click="catalogos.id = item.id;dialog.type='catalogos'; dialog.warning= true; dialog.message= 'estas por eliminar un catalogo'; "  ></v-btn>
                        </template>

                    </v-data-table>
                    
                </v-window-item>
            </v-window>

            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="catalogos.dialog = false; ">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



    <v-dialog v-model="catalogos.newc" transition="dialog-bottom-transition" width="50%">
        <v-card>
            <v-card-title v-if="catalogos.action=='new'">Nuevo catalogo {{ catalogos.type }}</v-card-title>
            <v-card-title v-else>Catalogo {{ catalogos.type }}</v-card-title>
            <v-card-text>
                <v-form @submit.prevent>
                    <v-row v-if="catalogos.type == 'TIPOS'">
                        <v-col cols="12" sm="12" md="4">
                            <v-text-field maxlength="3" :disabled="catalogos.action=='new' ? false : true"
                                v-model="catalogos.cod" :rules="rules" label="Tipo"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field v-model="catalogos.Descripcion " :rules="rules"
                                label="Descripcion"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="catalogos.type == 'MARCAS'">
                        <v-col cols="12" sm="12" md="4">
                            <v-text-field  :disabled="catalogos.action=='new' ? false : true"
                                v-model="catalogos.cod" :rules="rules" label="Marca"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-file-input label="Logo" id="img1"></v-file-input>
                        </v-col>
                    </v-row>
                    <v-row v-if="catalogos.type == 'TALLAS'">
                        <v-col cols="12" sm="12" md="4">
                            <v-select clearable v-model="catalogos.tipo" label="Tipo" :items="tipos.data"
                                item-title="DESCRIPCION" item-value="id">
                            </v-select>
                        </v-col>
                        
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field :disabled="catalogos.action=='new' ? false : true"
                                v-model="catalogos.cod" :rules="rules" label="Talla"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="catalogos.ALTURA" :rules="rules" label="ALTURA"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="catalogos.PECHO" :rules="rules" label="PECHO"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="catalogos.CADERA" :rules="rules" label="CADERA"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="3">
                            <v-text-field type="number" v-model="catalogos.CINTURA" :rules="rules" label="CINTURA"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-card-actions class="justify-end">
                        <v-btn variant="text" @click="catalogos.newc = false; ">Cancelar</v-btn>
                        <v-btn type="submit" @click="saveCatalogo">Guardar</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>


    </v-dialog>




    <v-dialog v-model="dialog.warning" transition="dialog-bottom-transition" width="auto">
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
            <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn variant="text" @click="dialog.warning = false ">Cancelar</v-btn>
                <v-btn v-if="dialog.type=='inventario'" @click="deleteInventario">Confirmar</v-btn>
                <v-btn v-else @click="deleteCatalogs ">Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>



<v-snackbar v-model="dialog.confirmacion" :timeout="2000" color="deep-purple-accent-4" elevation="24">

<strong>{{ dialog.message }}</strong>
</v-snackbar>


<v-dialog v-model="dialog.error" transition="dialog-bottom-transition" width="auto">
<v-card>
    <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
    <v-card-text>
        <div class="text-h6 pa-12">{{dialog.message}}</div>
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn variant="text" @click="dialog.error = false ">Cerrar</v-btn>
    </v-card-actions>
</v-card>
</v-dialog>


<v-dialog v-model="secuanciaINv.dialog" transition="dialog-bottom-transition" width="30%">
<v-card>
    <v-toolbar color="lime-darken-4" title="Edicion de consecutivo"></v-toolbar>
    <v-card-text>
        <v-row >
            <v-col cols="12" sm="12" md="4">
                <v-text-field  :disabled="true"
                    v-model="secuanciaINv.cod"  label="Codigo"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="8">
                <v-text-field v-model="secuanciaINv.consecutivo" label="Consecutivo" id="img1"></v-text-field>
            </v-col>
        </v-row>
    </v-card-text>
    <v-card-actions class="justify-end">
        <v-btn variant="text" @click="secuanciaINv.dialog = false ">Cerrar</v-btn>
        <v-btn @click="EditarConsecutivo" >Confirmar</v-btn>
    </v-card-actions>
</v-card>
</v-dialog>


<v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15"
    color="primary" indeterminate></v-progress-circular></v-dialog>


</template>
    
<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
// const url_images = process.env.VUE_APP_SERVICE_URL_IMGS; 

export default {
    data () {
        return {
            url_images: "",
            search: '',
            loadingTable: false,
            loadingTabledlg: false,
            secuanciaINv:{
                id: 0,
                dialog: false,
                cod: '',
                consecutivo: 0
            },
            inventario: {
                dialog: false,
                headers: [
                    { key: 'Codigo', title: 'Codigo' ,align: 'center'},
                    { key: 'Descripcion', title: 'Descripcion' ,align: 'center'},
                    { key: 'tpo', title: 'Tipo' ,align: 'center'},
                    { key: 'MARCA', title: 'Marca' ,align: 'center'},
                    { key: 'TALLA', title: 'Talla' ,align: 'center'},
                    { key: 'COLOR', title: 'Color' ,align: 'center'},
                    { key: 'CANT', title: 'Existencia' ,align: 'center'},
                    { key: 'apartados', title: 'Apartados' ,align: 'center'},
                    { key: 'PRCCMP', title: 'Costo Unitario' ,align: 'center'},
                    { key: 'PRCVNT', title: 'Costo de venta' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: [],
                newc: false,
                id: 0,
                Cod: '',
                Descripcion: '',  
                tipo: 0,
                marca: 0,
                talla:0,
                cantidad: 1,
                prccom: '',
                color: '',
                prcvnt: '',
                images: [],
                img: false,
                action: 'new',
                saveinv: false
            },
            catalogos: {
                dialog: false,
                id: 0,
                newc: false,
                type: 'TIPOS',
                action: 'new',
                cod: '',
                Descripcion:'',
                logo: '',
                tipo: 0,
                PECHO: 0.0,
                ALTURA: 0.0,
                CINTURA: 0.0,
                CADERA: 0.0
            },
            tipos:{
                headers: [
                    { key: 'TIPO', title: 'TIPO' ,align: 'center'},
                    { key: 'DESCRIPCION', title: 'DESCRIPCION' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: []
            },
            marcas:{
                headers: [
                    { key: 'MARCA', title: 'MARCA' ,align: 'center'},
                    { key: 'LOGO', title: 'LOGO' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: []
            },
            tallas:{
                headers: [
                    { key: 'TALLA', title: 'TALLA' ,align: 'center'},
                    { key: 'TIPO', title: 'TIPO' ,align: 'center'},
                    { key: 'ALTURA', title: 'ALTURA' ,align: 'center'},
                    { key: 'PECHO', title: 'PECHO' ,align: 'center'},
                    { key: 'CINTURA', title: 'CINTURA' ,align: 'center'},
                    { key: 'CADERA', title: 'CADERA' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: []
            },
            dialog: {
                    loading: false,
                    error: false,
                    warning: false,
                    confirmacion: false,
                    message: '',
                    type: ''
                },
            rules: [
                    value => {
                        if (value) return true
                        return 'Este campo no puede ir vacio'
                    },
                ],
        }
},
    created(){
        this.get();
        this.getCatalogos()
        this.url_images = process.env.VUE_APP_SERVICE_URL_IMGS
        console.log(this.url_images)
    },
    methods:{       
        showdtEdit(){
            this.secuanciaINv.dialog = true
            var codigoed = this.inventario.Cod.split('-');
            this.secuanciaINv.cod = codigoed[0]
            this.secuanciaINv.consecutivo = codigoed[1]
            console.log(codigoed)
        },
        EditarConsecutivo(){
            this.dialog.loading = true;
            let datos ={
                id: this.inventario.id,
                Codigo:  this.secuanciaINv.cod,
                consecutivo:  this.secuanciaINv.consecutivo
            }
            console.log(datos)

            axios.post(url + "/inventario/consecutivo", datos)
                    .then((result) => {
                        console.log(result.data)
                        if(result.data){
                            this.dialog.loading = false
                            this.secuanciaINv.dialog = false
                            this.get();
        this.getCatalogos()
                        }else{
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }

                    }).catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });
        },
        get(){

            this.dialog.loading = true;
            axios.get(url+"/inventarioALL")
                .then((result) => {  
                this.inventario.data = result.data
                this.dialog.loading = false
    
            }).catch(e=>{console.log(e)})
        },
        getCatalogos(){
            this.loadingTabledlg= true
            axios.get(url+"/inventario/create")
                .then((result) => {  
                this.tipos.data = result.data.tipos
                this.marcas.data = result.data.marcas
                this.tallas.data = result.data.Talla
                this.loadingTabledlg= false
    
            }).catch(e=>{console.log(e)})
        },
        saveInventario(){
            this.dialog.loading = true;
            this.loadingTable = true;

            let datas ={
                'Cod' : this.inventario.Cod
                ,'Descripcion' : this.inventario.Descripcion
                ,'tipo' :  this.inventario.tipo
                ,'marca' :  this.inventario.marca
                ,'talla' :  this.inventario.talla
                ,'color' :  this.inventario.color
                ,'cantidad' :  this.inventario.cantidad
                ,'prccom' :  this.inventario.prccom
                ,'prcvnt' :  this.inventario.prcvnt
            }


            if(this.inventario.Descripcion == '' || this.inventario.tipo == '' || this.inventario.marca == '' || this.inventario.talla == ''||  this.inventario.cantidad <= 0 || 
            this.inventario.prccom <= 0 || this.inventario.prcvnt <= 0){
                this.dialog.loading = false;
                this.loadingTable = false;
                return 0;
            }

            if(this.inventario.action == 'new'){
                axios.post(url + "/inventario", datas)
                    .then((result) => {

                        if (result.data.conf == true) {

                            this.inventario.saveinv = true
                            this.dialog.message = "inventario guardado correctamente con el codigo "+result.data.codigo
                            this.inventario.id = result.data.id
                            
                            this.dialog.loading = false;
                            this.loadingTable = false;
                            this.get()

                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false
                    }).catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });
            }else{
                axios.put(url + "/inventario/"+this.inventario.id, datas)
                .then((result) => {

                        if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = "inventario actualizado correctamente"
                            this.inventario.newc = false
                            this.dialog.loading = false;
                            this.loadingTable = false;
                            this.get()
                            
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false
                    }).catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });
            }
            

        },
        saveCatalogo(){
            this.dialog.loading = true;
            this.loadingTabledlg = true;
            let data =  {
                "COD" :  this.catalogos.cod,
                "Descripcon": this.catalogos.Descripcion,
                "logo": this.catalogos.logo,
                "tipo": this.catalogos.tipo,
                "PECHO": this.catalogos.PECHO,
                "ALTURA": this.catalogos.ALTURA,
                "CINTURA": this.catalogos.CINTURA,
                "CADERA": this.catalogos.CADERA,
            }

            var urlsave = url + "/cat/tipo";
            if( this.catalogos.type== 'TIPOS'){
                urlsave = url + "/cat/tipo"
            }
            else if(this.catalogos.type== 'MARCAS'){
                var imagen1 = document.getElementById('img1')
                let formdata = new FormData();
                formdata.append('COD',this.catalogos.cod) 
                formdata.append('imagen1',imagen1.files[0]) 
                data = formdata
                urlsave = url + "/cat/marca"
            }else(
                urlsave = url + "/cat/tallas"
            )

            axios.post(urlsave, data,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
                    .then((result) => {

                        if (result.data == true) {
                            this.dialog.confirmacion = true
                            this.dialog.message = this.catalogos.type+"guardado correctamente"
                            this.catalogos.newc = false
                            this.getCatalogos()
                            this.dialog.loading = false;
                            this.loadingTabledlg = false;
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false

                        // this.loadingTable = false
                    }).catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });

        },
        saveImages(){
            this.dialog.loading = true;
            let formdata = new FormData();

            var archuvos2 = document.getElementById('imagesinv')
            for(let files of archuvos2.files){
                formdata.append('imagen1[]',files) 
            }

            axios.post(url + "/inventario/images/save/"+this.inventario.id,formdata,{headers: {'Content-Type': 'multipart/form-data'}})
                    .then((result) => {
                        console.log(result.data)
                        if (result.data.conf == true) {
                            this.inventario.images = result.data.images
                            this.dialog.confirmacion = true
                            this.dialog.message = "Imagenes cargadas correctamente"
                            this.dialog.loading = false;
                            this.inventario.img = false;
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    })
        },
        deleteimg(image){
            this.dialog.loading = true;
            axios.delete(url + "/inventario/images/"+image.id)
                    .then((result) => {

                        if (result.data.conf == true) {
                            this.inventario.images = result.data.images
                            this.dialog.confirmacion = true
                            this.dialog.message = "Imagenes elimnada correctamente"
                            this.dialog.loading = false;
                            this.inventario.img = false;
                        } else {
                            this.dialog.loading = false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false

                        // this.loadingTable = false
                    })
            .catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });

        },
        deleteCatalogs(){
            this.loadingTabledlg= true
            this.dialog.loading = true
            var urlsave = url + "/cat/tipo/";
            if( this.catalogos.type== 'TIPOS'){
                urlsave = url + "/cat/tipo/"
            }
            else if(this.catalogos.type== 'MARCAS'){
                urlsave = url + "/cat/marca/"
            }else(
                urlsave = url + "/cat/tallas/"
            )

            axios.delete(urlsave+this.catalogos.id)
                    .then((result) => {

                        if (result.data == true) {
                            this.dialog.loading = false
                            this.dialog.confirmacion = true
                            this.dialog.message = this.catalogos.type+" eliminado correctamente"
                            this.getCatalogos()

                            this.loadingTabledlg= false
                            this.dialog.loading = false
                        } else {
                            this.dialog.loading = false
                            this.loadingTabledlg= false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false

                        // this.loadingTable = false
                    })
            .catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });
        },
        deleteInventario(){
            this.loadingTable= true
            this.dialog.loading = true

            axios.delete(url + "/inventario/"+this.inventario.id)
                    .then((result) => {

                        if (result.data == true) {
                            this.dialog.loading = false
                            this.dialog.confirmacion = true
                            this.dialog.message = "Inventario eliminado correctamente"
                            this.get()

                            this.loadingTable= false
                            this.dialog.loading = false
                        } else {
                            this.dialog.loading = false
                            this.loadingTable= false
                            this.dialog.error = true
                            this.dialog.message = "No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                        this.dialog.warning = false
                        this.get();
                        // this.loadingTable = false
                    })
            .catch((e) => {
                        console.log(e);
                        this.dialog.loading = false
                        this.dialog.error = true
                        this.dialog.message = "Ocurrio un error, intente de nuevo mas tarde"
                    });
        },
        updtinv(item){
            this.inventario.newc= true
            this.inventario.id = item.id
            this.inventario.Cod = item.Codigo
            this.inventario.Descripcion = item.Descripcion
            this.inventario.tipo = item.tipo_id
            this.inventario.marca = item.marca_id
            this.inventario.talla = item.talla_id
            this.inventario.cantidad = item.CANT
            this.inventario.prccom = item.PRCCMP
            this.inventario.prcvnt = item.PRCVNT
            this.inventario.color = item.COLOR
            this.inventario.action= 'update'

            this.dialog.loading = true;
            axios.get(url + "/inventario/"+item.id)
                    .then((result) => {
                        console.log(result.data)
                        this.inventario.images = result.data
                        this.dialog.loading = false;
                    })
            // this.inventario.images = []
        },
        newcat(){
            this.catalogos.newc= true
            this.catalogos.action= 'new'
            this.catalogos.id= 0
            this.catalogos.cod= ''
            this.catalogos.Descripcion=''
            this.catalogos.logo= ''
            this.catalogos.tipo= '',
            this.catalogos.PECHO= 0.0
            this.catalogos.ALTURA= 0.0
            this.catalogos.CINTURA= 0.0
            this.catalogos.CADERA= 0.0
        },
        newinv(){
            this.inventario.id = 0
            this.inventario.Cod = ''
            this.inventario.Descripcion = ''
            this.inventario.tipo = ''
            this.inventario.marca = ''
            this.inventario.talla = ''
            this.inventario.cantidad = 0
            this.inventario.prccom = 0
            this.inventario.prcvnt = 0
            this.inventario.color = ''
            this.inventario.action= 'new'
        }
    }
}
</script>
    
    <style>
    
    </style>