<template>
<v-row  class="filtrosdash">
        <v-btn variant="text" style="font-size: large;">DAHBOARD </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->

        <v-select  class="filtro"
            v-model="filtopor"
            :items="['Por periodo','Por dia', 'Por semana', 'Por mes' ]"
            density="compact"
            label="Filtrar por:"
        ></v-select>

        <v-select  v-if="filtopor == 'Por periodo'" class="filtro"
            v-model="periodo"
            :items="periodos"
            density="compact"
            label="Periodo"
        ></v-select>

      <v-text-field  v-if="filtopor == 'Por dia'" class="filtro"
            v-model="periodosft.dia" type="date"
            label="Dia"
            density="compact"
          ></v-text-field>


          <v-select  v-if="filtopor == 'Por semana'" class="filtro"
              v-model="periodosft.semana"
              :items="periodosft.semanas"
              item-title="periodo"
              item-value="Semana"
              label="Semana"
              density="compact"
            ></v-select>

            <v-select  v-if="filtopor == 'Por mes'" class="filtro"
              v-model="periodosft.mes"
              :items="periodosft.meses"
              item-title="mes"
              item-value="numM"
              label="Mes"
              density="compact"
            ></v-select>

        <v-tooltip text="Filtrar" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-filter" color="indigo-darken-4" @click="aplicarFiltros" style="margin-right: 5px;"></v-btn>
            </template>
        </v-tooltip>

        
        <v-tooltip text="Imprimir" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-printer" color="indigo-darken-4" @click="reportes.dialog=true" style="margin-right: 5px;"></v-btn>
            </template>
        </v-tooltip>
        <v-tooltip text="Actualizar" location="bottom">
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="indigo-darken-4" @click="getVentas" ></v-btn>
            </template>
        </v-tooltip>
    </v-row>



    <v-dialog  v-model="reportes.dialog" class="widthdialog">
      <v-card
          prepend-icon="mdi-file-pdf-box"
          title="Selecciona reporte"
        >
          <v-divider class="mt-3"></v-divider>

          <v-card-text class="px-4" >
            <v-list>

              <v-list-item color="primary" @click=" this.reportes.typerep = 'lbdiario'; makeurl()"
                rounded="shaped">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-pdf-box"></v-icon>
                  </template>

                  <v-list-item-title>Reporte contable (Libro de Diario)</v-list-item-title>
              </v-list-item>

              <v-list-item value="ventas" color="primary" @click="reportes.typerep = 'ventas';  makeurl()"
                rounded="shaped">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-pdf-box"></v-icon>
                  </template>

                  <v-list-item-title>Lista de ventas</v-list-item-title>
              </v-list-item>

              <v-list-item value="gastos" color="primary"  @click=" reportes.typerep = 'gastos'; makeurl()"
                rounded="shaped">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-pdf-box"></v-icon>
                  </template>

                  <v-list-item-title>Lista de Gastos</v-list-item-title>
              </v-list-item>
<!-- 
              <v-list-item color="primary"  @click="reportes.pdf = true; reportes.typerep = 'Apartados'"
                rounded="shaped">
                  <template v-slot:prepend>
                    <v-icon icon="mdi-file-pdf-box"></v-icon>
                  </template>

                  <v-list-item-title>Lista de Apartados</v-list-item-title>
              </v-list-item> -->


            </v-list>

          </v-card-text> 
      </v-card>

    </v-dialog>
<br><br>

<v-row>
  <v-card class="mx-auto dtagenDas" >

    <v-card-text>
      <div class="font-weight-bold">Resumen de Datos</div>

      <v-timeline density="compact">
        <v-timeline-item
          v-for="message in messages"
          :key="message.color"
          :dot-color="message.color"
          size="x-small"
        >
          <div >
            <div class="font-weight-normal">
              <strong>{{ message.from }}</strong> 
            </div>

            <div>$ {{ message.message }}</div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>  
  </v-card>
  <v-card  class="chartdash" >
    <div class="dashcharts">
      <canvas id="myChart1" > </canvas>
    </div>
    
  </v-card>
</v-row>




<v-dialog v-model="reportes.pdf" persistent  width="1024" >
    
    <v-card>
        <v-card-title>
            <span class="text-h5"> Reporte  </span>
        </v-card-title>
    <v-card-text style="width: 100%; height: 600px;">
        <iframe style="width: 100%; height: 100%;" :src="urlreport" frameborder="0"></iframe>
    </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  color="blue-darken-1" variant="text"
                @click="reportes.pdf = false">
                Cerrar
            </v-btn>                      
        </v-card-actions>
    </v-card>
</v-dialog>





<v-dialog v-model="loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15"
    color="primary" indeterminate></v-progress-circular></v-dialog>


</template>

<script>
import Chart from 'chart.js/auto'
import axios from "axios";
import moment from 'moment';
const url = process.env.VUE_APP_SERVICE_URL
let myChart
export default {
  data(){
    return{ 
      urlreport: '',
      urldat: url,
      loading: false,
    Tventas: 0,
    Tgans: 0,
    Tgasts: 0,
    periodosft: {
      dia: '',
      semanas: [],
      semana: '',
      meses: [],
      mes: ''
    },
    reportes: {
      dialog: false,
      repS: '',
      pdf: false,
      typerep: '',
      labels: [],
      ventas: [],
      gastos: [],
      ganancias: []
    },
    filtopor: 'Por periodo',
    periodo: 'Solo hoy',
    periodos: ['Solo hoy','Ultima semana', 'Ultimo mes', 'Todo' ],
    messages: [
        {
          from: 'Total de ventas',
          message: '0',
          color: 'deep-purple-lighten-1',
        },
        {
          from: 'Total de ganancias',
          message: '0',
          color: 'green',
        },
        {
          from: 'Total de gastos',
          message: '0',
          color: 'purple-darken-4',
        },
        {
          from: 'Total de efectivo',
          message: '0',
          color: 'pink-darken-4',
        },
        {
          from: 'Total de Transferencias/tarjetas',
          message: '0',
          color: 'ndigo-darken-4',
        },
        {
          from: 'Total efectivo - gastos',
          message: '0',
          color: 'teal-darken-4',
        },
      ],
  }
  },
  created(){
    this.getVentas()
  
  },
  methods:{
    async  getVentas(){
            this.loading = true

            await axios.get(url+"/dashboard")
                .then((result) => {  
                  console.log(result.data)
                this.messages[0].message =  result.data.tventa
                this.messages[1].message =  result.data.Tganancia
                this.messages[2].message =  result.data.gastos
                this.reportes.ventas = [ parseFloat(result.data.tventa), parseFloat(result.data.Tganancia),result.data.gastos]
                this.loading = false
                
                this.Graficar()
            }).catch(e=>{console.log(e)})
        },
        Graficar(){   
          let labels = []    
          let ventasc = []
          let ganancias = []
          let gastos = []
          var type = 'bar'

          if(this.periodo== 'Solo hoy' || this.filtopor == 'Por dia'){
            labels = ['Datos generales']
            ventasc.push(this.messages[0].message)
            ganancias.push(this.messages[1].message)
            gastos.push(this.messages[2].message)
          }
        
          if(this.filtopor == 'Por periodo' && this.periodo== 'Ultima semana' || this.filtopor == 'Por semana'){
            type = 'line'
            labels = this.reportes.labels
            ventasc = this.reportes.ventas
            ganancias = this.reportes.ganancias
            gastos = this.reportes.gastos
          }


            var ctx = document.getElementById('myChart1').getContext('2d');
            if (myChart) {
                    myChart.destroy();
            }  
                myChart = new Chart(ctx, {
                type: type,
                data: {
                    labels:labels ,
                    datasets: [
                    {
                        data: gastos,           
                        label: "Gastos",
                        fill: true,
                        backgroundColor: ['rgba(226, 9, 137, 0.9)'  ],         
                        borderColor: ['rgb(226, 9, 137)',],
                        borderWidth: 1
                    },
                    {
                        data: ganancias,           
                        label: "Ganancias",
                        fill: true,
                        backgroundColor: ['rgba(68, 226, 9, 0.6)'  ],         
                        borderColor: ['rgb(68, 226, 9)',],
                        borderWidth: 1
                    },{
                        data: ventasc,           
                        label: "Ventas",
                        fill: true,
                        backgroundColor: ['rgba(9, 88, 226, 0.3)'  ],         
                        borderColor: ['rgb(9, 88, 226)',],
                        borderWidth: 1
                    },
                  ]
                },
                options: {
                    // indexAxis: 'y',
                    labels: false
                    
                },
                
            });
        },
        getReport(){
          axios.get(url+"/dashboard")
                .then((result) => {  
                this.messages[0].message =  result.data.tventa
                this.messages[1].message =  result.data.Tganancia
                this.messages[2].message =  result.data.gastos
                this.loading = false
                
                var datos = [ parseFloat(result.data.tventa), parseFloat(result.data.Tganancia),result.data.gastos]
                this.Graficar(datos)
            }).catch(e=>{console.log(e)})
        },

       async aplicarFiltros() {
          let datos = []
          if(this.filtopor == 'Por periodo'){
            datos = {
              "filtrop" : this.filtopor,
              "periodo": this.periodo
            }
          }
          else if(this.filtopor == 'Por dia'){
            datos = {
              "filtrop" : this.filtopor,
              "periodo": moment(this.periodosft.dia).format('DD-MM-YYYY')
            }
          }
          else if(this.filtopor == 'Por semana'){
            datos = {
              "filtrop" : this.filtopor,
              "periodo": this.periodosft.semana
            }
          }
          else if(this.filtopor == 'Por mes'){
            datos = {
              "filtrop" : this.filtopor,
              "periodo": this.periodosft.mes
            }
          }
          

          this.loading = true

          await axios.post(url+"/dashboard/data",datos)
                .then((result) => {  
                  console.log(result.data)
                this.messages[0].message =  result.data.tventa
                this.messages[1].message =  result.data.Tganancia
                this.messages[2].message =  result.data.gastos
                this.messages[3].message =  result.data.TEfectivo
                this.messages[4].message =  result.data.TtansEfec
                this.messages[5].message =  result.data.entregascierre
                this.reportes.labels = result.data.labels
                this.reportes.ventas = result.data.ventas
                this.reportes.ganancias = result.data.ganancias
                this.reportes.gastos = result.data.gastoslst
                this.loading = false
                
                this.Graficar()

            }).catch(e=>{console.log(e)})

          
        },
        makeurl(){
          if(this.filtopor == 'Por periodo'){
            this.urlreport =  this.urldat+'/dashboard/rep/'+this.reportes.typerep+'/Porperiodo/'+this.periodo;
          }
          else if(this.filtopor == 'Por dia'){
            this.urlreport =  this.urldat+'/dashboard/rep/'+this.reportes.typerep+'/Podia/'+ moment(this.periodosft.dia).format('DD-MM-YYYY');
          }
          else if(this.filtopor == 'Por semana'){
            this.urlreport =  this.urldat+'/dashboard/rep/'+this.reportes.typerep+'/Porsemana/'+this.periodosft.semana;
          }
          else if(this.filtopor == 'Por mes'){
            this.urlreport =  this.urldat+'/dashboard/rep/'+this.reportes.typerep+'/Pormes/'+this.periodosft.mes;
          }


          this.reportes.pdf = true; 
         
        }
  },
  watch: {   
        filtopor: async function(value){
          if(value == 'Por dia'){
            // const fecha = new Date();
            // var day = fecha.getDate(); 
            // var month = fecha.getMonth() + 1; 
            // var year = fecha.getFullYear();              

            // this.periodosft.dia = `${day}/04/${year}`
            // console.log(`${year}/04/${day}`)  
          }
          else if(value == 'Por semana'){
                await axios.get(url+"/dashboard/weeks")
                    .then((result) => {  
                    this.periodosft.semanas = result.data
                }).catch(e=>{console.log(e)})
          }
          else if(value == 'Por mes'){
            this.periodosft.meses = [
              {'numM': 1,'mes': 'Enero'}, 
              {'numM': 2,'mes': 'Febrero'}, 
              {'numM': 3,'mes': 'Marzo'}, 
              {'numM': 4,'mes': 'Abril'}, 
              {'numM': 5,'mes': 'Mayo'}, 
              {'numM': 6,'mes': 'Junio'}, 
              {'numM': 7,'mes': 'Julio'}, 
              {'numM': 8,'mes': 'Agosto'}, 
              {'numM': 9,'mes': 'Septiembre'}, 
              {'numM': 10,'mes': 'Octubre'}, 
              {'numM': 11,'mes': 'Noviembre'}, 
              {'numM': 12,'mes': 'Diciembre'}, 
            ]
          }
        },
        
    }
}
</script>


<style>

</style>